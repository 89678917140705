<template>
  <hit-modal
    :title="t('hit-app.common.printer-settings')"
    prefix-icon="printer"
    popup-size="small"
    @clickOutside="emitCancel"
  >
    <div class="flex flex-col space-y-4">
      <div v-if="chooseTemplate">
        <hit-app-input-template
          :value="selectedTemplate"
          :template-prefix="templateType"
          @change="(value) => (selectedTemplate = value)"
        />
      </div>
      <div v-if="chooseLayout">
        <hit-app-input-print-layout
          :value="selectedPrintLayout"
          @change="(value) => (selectedPrintLayout = value)"
        />
      </div>
      <div v-if="choosePrintLanguage">
        <hit-app-input-print-language
          :value="selectedPrintLanguage"
          @change="(value) => (selectedPrintLanguage = value)"
        />
      </div>
      <div v-if="chooseOneOrMultipleFiles">
        <hit-input-radio-button
          :options="fileOptions"
          :value="fileOptionValue"
          @change="(v) => (fileOptionValue = v)"
        />
      </div>
      <div class="flex flex-row justify-between">
        <div>
          <hit-button
            :label="t('hit-app.printer-configuration.set-as-default')"
            color="accent"
            prefix-icon="save"
            @click="setAsDefault"
          />
        </div>
        <div class="flex justify-end">
          <div class="flex space-x-2">
            <div>
              <hit-button
                :label="t('hit-components.common.cancel')"
                :disabled="isPrinting"
                color="button"
                prefix-icon="clear"
                @click="emitCancel"
              />
            </div>
            <div>
              <hit-button
                :disabled="readyToPrint"
                :label="t('hit-base.common.print')"
                color="accent"
                prefix-icon="check"
                :show-spinner="isPrinting"
                @click="printFiles"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </hit-modal>
</template>

<script>
import {
  HitButton,
  HitIcon,
  HitInputRadioButton,
  HitModal,
  HitUUIDUtils,
} from '@hit/components/src';
import HitAppInputPrintLayout from '@hit/base/src/components/hit/form/input/HitAppInputPrintLayout.vue';
import HitAppInputPrintLanguage from '@hit/base/src/components/hit/form/input/HitAppInputPrintLanguage.vue';
import {useI18n} from 'vue-i18n';
import HitAppInputTemplate from '@hit/base/src/components/hit/form/input/HitAppInputTemplate.vue';
import {
  ActionService,
  DataService,
  useNotificationsStore,
  useConfigurationStore,
} from '@hit/base';
import {HitBannerNotificationItem} from '@hit/base/src/store/pinia/notifications';
import HitSpinner from '@hit/components/src/components/icon/HitSpinner.vue';

export default {
  name: 'HitAppPrintConfiguration',
  components: {
    HitIcon,
    HitModal,
    HitAppInputPrintLanguage,
    HitAppInputPrintLayout,
    HitButton,
    HitAppInputTemplate,
    HitInputRadioButton,
    HitSpinner,
  },
  props: {
    chooseTemplate: {
      type: Boolean,
      default: true,
    },
    chooseLayout: {
      type: Boolean,
      default: true,
    },

    /**
     * Defines the templates the user can select from(form, transaction, etc.)
     */
    templateType: {
      type: String,
      required: false,
      default: 'form',
    },

    /**
     * Array of the UUIDs we want to print
     */
    idsToPrint: {
      type: [String, Array],
      required: false,
      default: () => {},
    },
  },
  setup() {
    const {t} = useI18n();
    const config = useConfigurationStore();
    return {t, config};
  },

  data() {
    return {
      isPrinting: false,
      selectedTemplate: null,
      selectedPrintLayout: null,
      selectedPrintLanguage: null,
      fileOptions: [
        {
          label: this.t('hit-app.printer-configuration.single-file'),
          id: 'single-file',
        },
        {
          label: this.t('hit-app.printer-configuration.multiple-files'),
          id: 'multiple-files',
        },
      ],
      fileOptionValue: 'single-file',
    };
  },
  computed: {
    /**
     * The choice between one single file or multiple files can only be done when
     * the user selected multiple files to print.
     */
    chooseOneOrMultipleFiles() {
      return Array.isArray(this.idsToPrint) && this.idsToPrint.length > 1;
    },

    /**
     * In soe cases, we can choose the language the pdf should be printed in
     */
    choosePrintLanguage() {
      return ['form'].includes(this.templateType);
    },

    /**
     * Following the type of report, we use different routes to generate the data
     */
    reportActionRoute() {
      switch (this.templateType) {
        case 'form':
          return 'report/form';
        case 'transaction':
          return 'report/transaction';
        case 'periodicBill':
          return 'periodic-billing/report/periodic_bill';
        case 'projectPart':
          return 'report/project-part';
        default:
          throw Error('Unsupported template type for the print config');
      }
    },

    /**
     * Flag that checks if all the needed values have been selected -> enables print button
     */
    readyToPrint() {
      return !(
        (!this.chooseTemplate || this.selectedTemplate) &&
        (!this.chooseLayout || this.selectedPrintLayout) &&
        (!this.chooseLanguage || this.selectedPrintLanguage)
      );
    },
  },
  watch: {
    selectedTemplate(newValue) {
      DataService.read('config', {
        attributes: 'value',
        filters: {
          label: 'eq.report.config',
          name: `eq.${newValue}`,
        },
      }).then((res) => {
        if (res && res.data && res.data.length > 0) {
          this.selectedPrintLayout = res.data[0].value.layout_id;
        }
      });
    },
  },
  methods: {
    /**
     * Method executed when the user clicks on the print button in the modal.
     * During the print process, we disable buttons and cancel and display spinner
     */
    async printFiles() {
      this.isPrinting = true;
      switch (this.fileOptionValue) {
        case 'single-file':
          await this.printInSingleFile();
          break;
        case 'multiple-files':
          await this.printInMultipleFiles();
          break;
      }
      this.isPrinting = false;
      this.$emit('printed');
    },

    /**
     * Prints all the selected files in one single PDF
     */
    async printInSingleFile() {
      await this.printFile(this.idsToPrint);
    },

    /**
     * Uses one PDF per item to print. For each does not work for async operations
     */
    async printInMultipleFiles() {
      for (const item of this.idsToPrint) {
        await this.printFile([item]);
      }
    },

    /**
     * Makes the call to the report API to print the list of records
     */
    async printFile(items) {
      await ActionService.post(this.reportActionRoute, {
        values: {
          items_to_print: items,
          template_id: this.selectedTemplate,
          page_layout: this.selectedPrintLayout,
          preferred_language: this.selectedPrintLanguage,
        },
        downloadAsPdf: true,
      });
    },

    /**
     * Creates or updates the config record in the database for the selected template
     */
    async setAsDefault() {
      // We can not use upsert for the config table
      await DataService.delete('config', {
        label: 'eq.report.config',
        name: `eq.${this.selectedTemplate}`,
      });
      DataService.upsert('config', {
        id: HitUUIDUtils.generate(),
        label: 'report.config',
        name: this.selectedTemplate,
        visible: false,
        customisable: false,
        datatype: 'JSON',
        value: {
          layout_id: this.selectedPrintLayout,
        },
        global: false,
      }).then(() =>
        useNotificationsStore().insertBannerNotification(
          this.t('hit-base.banners.set-print-config'),
          HitBannerNotificationItem.SUCCESS
        )
      );
    },

    /**
     * We handle the cancel event differently when the report is being printed or not
     */
    emitCancel() {
      if (!this.isPrinting) {
        this.$emit('cancel');
      }
    },
  },
};
</script>

<style scoped></style>
