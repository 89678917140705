<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-address')"
    route="address"
    auto-complete-id="address"
    :table-attributes="tableAttributes"
    :default-sort="{property: 'no', order: 'asc'}"
    :filter-id="filterId"
    :filter-id-column="filterIdColumn"
    :only-load-active-records="onlyLoadActiveRecords"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {
  HitContainerAttribute,
  ATTRIBUTE_TYPES,
} from '../../container/HitContainerAttribute';
import {useI18n} from 'vue-i18n';

export default {
  name: 'HitAddressBrowser',
  components: {
    HitEntityBrowser,
  },
  props: {
    filterId: {
      type: String,
      required: false,
    },
    filterIdColumn: {
      type: String,
      required: false,
    },
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
    tagFilters: {
      type: Array,
      required: false,
      default: null,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      tableAttributes: {
        number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: 'no',
          tableProperties: {
            header: this.t('hit-app.common.number'),
            maxWidth: '0.5fr',
          },
        }),
        designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-base.common.designation'),
            mainSearch: true,
            maxWidth: '1fr',
          },
        }),
        street: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-app.common.street'),
            maxWidth: '1fr',
          },
        }),
        houseNo: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-app.common.house-number'),
            maxWidth: '0.5fr',
            sharable: true,
          },
          column: 'house_no',
        }),
        boxNo: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-app.common.box-number'),
            maxWidth: '0.5fr',
            sharable: true,
          },
          column: 'box_no',
        }),
        postcode: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-app.common.postcode'),
            maxWidth: '0.5fr',
          },
        }),
        city: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-app.common.city'),
            maxWidth: '1fr',
          },
        }),
        country: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-base.common.country'),
            maxWidth: '0.5fr',
          },
        }),
        supplier: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
          tableProperties: {
            propertyName: 'supplier',
            header: this.t('hit-app.common.supplier'),
            maxWidth: '0.5fr',
          },
        }),
        potentialSupplier: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
          tableProperties: {
            propertyName: 'potential_supplier',
            header: this.t('hit-app.common.potential-supplier'),
            maxWidth: '0.5fr',
          },
          column: 'potential_supplier',
        }),
        active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
          tableProperties: {
            propertyName: 'active',
            header: this.t('hit-app.common.active'),
            maxWidth: '0.5fr',
          },
        }),
        tags: new HitContainerAttribute(ATTRIBUTE_TYPES.TAGS, {
          tableProperties: {
            header: this.t('hit-base.common.tags'),
            maxWidth: '1fr',
          },
        }),
      },
    };
  },
};
</script>
