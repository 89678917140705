<template>
  <hit-base-input
    :disabled="disabled"
    :full-width="adaptToContent"
    :inline-input="inlineInput"
    :label="label"
    :row-label="rowLabel"
    :validation-state="validationState"
    use-custom-height
  >
    <OnClickOutside @trigger="onClickOutside">
      <div
        :ref="(el) => (inputDomEl = el)"
        :class="{
          'w-full': !adaptToContent || fullWidth,
          'xl:w-1/2': !adaptToContent && !fullWidth,
          'border rounded': bordersVisible,
          'border-input':
            (valid && pristine) || !validationState || (valid && dirty),
          'border-danger': invalid && dirty && bordersVisible,
          'bg-input': !transparentInput,
          'bg-transparent': transparentInput,
        }"
        class="inline-block h-full text-input focus:border-accent focus:ring-transparent focus:outline-none"
        tabindex="0"
        @keyup.enter.stop="handleEnterClick"
      >
        <div
          :class="{
            clear: clearable,
          }"
          class="hit-select h-full"
        >
          <div
            v-if="customIcon"
            class="h-full flex items-center"
            @click.stop="toggleDropdownVisibility"
          >
            <hit-icon
              :color="iconColor"
              :icon="customIcon"
              clickable
            />
          </div>
          <div
            v-else-if="customUserName"
            class="rounded-md cursor-pointer w-7 h-7 flex justify-center items-center text-gray-500 hover:bg-gray-100"
          >
            <div>
              {{ customUserName }}
            </div>
          </div>
          <div
            v-else
            :class="{clear: clearable}"
            class="grid items-center hit-select h-full cursor-pointer"
            @click.stop="toggleDropdownVisibility"
          >
            <div
              v-if="!multiple || (multiple && selectValue.length === 0)"
              :class="{
                'border-padding': bordersVisible,
              }"
              class="cursor-pointer w-full inline-grid items-center"
            >
              <div
                :class="{
                  'text-input': value,
                  'text-input-light': !value,
                }"
                :disabled="disabled"
                :placeholder="placeholder"
                class="cursor-pointer w-full hit-select-input truncate flex items-center gap-2"
              >
                <div v-if="getItemById(value)?.icon">
                  <hit-icon :icon="getItemById(value)?.icon" />
                </div>
                <div v-if="getItemById(value)?.flag">
                  <hit-country-flag :country="getItemById(value).flag" />
                </div>
                <slot
                  v-if="value"
                  :item="
                    multiple && selectValue.length === 0
                      ? null
                      : getItemById(value)
                  "
                  :name="
                    hasSelectedItemDesignationSlot
                      ? 'selectedItemDesignation'
                      : 'itemDesignation'
                  "
                >
                  {{ selectedItemLabel }}
                </slot>
                <template v-if="!value">
                  {{ placeholder }}
                </template>
              </div>
            </div>

            <div
              v-if="displayCancelIcon"
              class="pr-1 cursor-pointer inline-grid justify-center items-center"
            >
              <hit-icon
                class="cursor-pointer text-input outline-none focus:outline-none"
                icon="clear"
                :clickable="true"
                @click.stop="clearInput"
              />
            </div>
            <div
              v-if="multiple && selectValue.length > 0"
              class="w-full flex flex-wrap items-center gap-1 px-1 cursor-pointer"
            >
              <div
                v-for="selectVal of selectValue"
                :key="selectVal"
                :style="{
                  'background-color': `${getItemBackground(selectVal)}`,
                  color: `${getItemFontColour(selectVal)}`,
                }"
                class="flex-row flex font-medium py-1 px-1 rounded h-6 items-center"
              >
                <div
                  class="px-1 text-xs font-normal leading-none max-w-full grid grid-flow-col gap-2"
                >
                  <div v-if="getItemById(value)?.icon">
                    <hit-icon
                      :icon="getItemById(value).icon"
                      size="sm"
                    />
                  </div>
                  <div v-if="getItemById(selectVal)?.flag">
                    <hit-country-flag :country="getItemById(selectVal)?.flag" />
                  </div>
                  <slot
                    :item="getItemById(selectVal)"
                    name="itemDesignation"
                  >
                    {{ getItemLabel(getItemById(selectVal)) }}
                  </slot>
                </div>
                <div class="inline-grid items-center">
                  <hit-icon
                    :clickable="true"
                    class="cursor-pointer outline-none focus:outline-none ml-1"
                    color="panel"
                    icon="clear"
                    size="xs"
                    @click.stop="onSelect(selectVal)"
                  />
                </div>
              </div>
            </div>
            <div
              :class="{
                'border-l border-input': divisionLine,
              }"
              class="h-full w-8 pl-1 pr-1 text-center cursor-pointer inline-grid items-center"
            >
              <hit-icon
                :icon="isDropdownVisible ? 'expand-top' : 'expand-bottom'"
                class="w-6 h-6 outline-none focus:outline-none inline-grid justify-center items-center"
              />
            </div>
          </div>
          <div class="relative">
            <Teleport
              v-if="isDropdownVisible"
              to="#root-element"
            >
              <div
                v-if="isDropdownVisible"
                :style="dropdownStyle"
                class="absolute shadow rounded overflow-y-auto z-40 inline-block w-auto bg-input"
                @keydown.tab.prevent="incrementPreselectedItem"
                @keyup.enter="handleEnterClick"
              >
                <div
                  v-if="!currentCustomAction"
                  class="flex flex-col w-full"
                >
                  <div
                    v-if="filterable"
                    class="cursor-pointer w-full border-input rounded-t border-b hover:bg-input-hover"
                  >
                    <div
                      class="flex w-full items-center p-2 pl-2 border-none relative"
                    >
                      <div class="w-full items-center flex-auto rounded">
                        <hit-search
                          :ref="(ref) => (hitSearchRef = ref)"
                          :class="searchWidth"
                          :instant-focus="true"
                          :placeholder="t('hit-components.common.search')"
                          :value="searchValue"
                          @input="filter"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="noItemsDropdown === 0"
                    class="w-full border-gray-100 rounded-t"
                  >
                    <div
                      class="flex w-full items-center p-2 pl-2 border-none bg-input relative"
                    >
                      <div class="w-full items-center flex">
                        <div class="mx-2 leading-6">
                          <span v-if="!optionsLoading">
                            {{ noMatchingResultText }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-for="(item, itemId) in itemsToDisplaySorted"
                  :id="'select-item-' + getItemId(item, itemId)"
                  :key="getItemId(item, itemId)"
                  class="cursor-pointer w-full border-gray-100 text-input"
                  @click="onSelect(getItemId(item, itemId), item)"
                  @mouseover="resetPreselectedItem"
                >
                  <div
                    :class="{
                      'border-accent': isItemSelected(getItemId(item, itemId)),
                      'border-none': !isItemSelected(getItemId(item, itemId)),
                      'bg-input-hover text-white': isItemPreselectedViaTab(
                        getItemId(item, itemId)
                      ),
                    }"
                    class="flex w-full items-center p-2 pl-2 bg-input border-l-2 relative hover:bg-input-hover hover:text-white hover:border-accent hit-select-item"
                  >
                    <div class="w-full items-center flex">
                      <div
                        class="mx-2 leading-6 grid grid-flow-col gap-2 break-words whitespace-normal"
                      >
                        <div v-if="item?.icon">
                          <hit-icon
                            :icon="item.icon"
                            size="sm"
                          />
                        </div>
                        <div v-if="item.flag">
                          <hit-country-flag :country="item.flag" />
                        </div>
                        <slot
                          :item="item"
                          name="itemDesignation"
                        >
                          {{ getItemLabel(item, true) }}
                        </slot>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-for="customAction of customActions"
                  :key="customAction.buttonLabel"
                  class="w-full border-gray-100 rounded-t cursor-pointer border-t"
                  @click.stop="showCustomActionSlot(customAction)"
                >
                  <div
                    class="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative"
                  >
                    <div class="w-full items-center flex">
                      <hit-icon
                        v-if="customAction.buttonIcon"
                        :icon="customAction.buttonIcon"
                      />
                      <div class="mx-2 leading-6">
                        {{ customAction.buttonLabel }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Teleport>
            <!-- stop click propagation (do not trigger click-outside) -->
            <div
              v-if="currentCustomAction"
              class="inline-grid w-full creation-menu bg-input gap-1"
              @click.stop
            >
              <div
                class="custom-action-header grid gap-1 border-b items-center p-1 h-12 border-gray-100"
              >
                <hit-icon
                  :clickable="true"
                  icon="back"
                  @click.stop="hideCustomActionSlot()"
                />
                <span>{{ currentCustomAction.buttonLabel }}</span>
              </div>
              <div class="custom-action-body p-1">
                <slot
                  :close-dropdown-fn="closeDropdown"
                  :close-slot-fn="hideCustomActionSlot"
                  :name="currentCustomAction.id"
                  :select-fn="onSelect"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </OnClickOutside>
    <template #help>
      <!-- @slot Help of the select -->
      <slot name="help" />
    </template>
  </hit-base-input>
</template>

<script>
import HitFormValidationMixin from '../../../mixins/form/HitFormValidationMixin';
import TailwindUtils from '../../../utils/tailwind/tailwind';
import HitSelectMixin from '../../../mixins/form/HitSelectMixin';
import {useI18n} from 'vue-i18n';
import HitBaseInput from '../input/HitBaseInput.vue';
import HitIcon from '../../icon/HitIcon.vue';
import HitSearch from '../../search/HitSearch.vue';
import {OnClickOutside} from '@vueuse/components';
import {nextTick} from 'vue';
import {HitBreakpointsMixin} from '../../../mixins';
import {ColourUtils} from '../../../utils/colour';
import HitCountryFlag from '../../icon/HitCountryFlag.vue';

export default {
  name: 'HitSelect',
  components: {
    HitBaseInput,
    HitIcon,
    HitSearch,
    OnClickOutside,
    HitCountryFlag,
  },
  mixins: [HitSelectMixin, HitFormValidationMixin, HitBreakpointsMixin],
  props: {
    /**
     * Function to get the item label
     */
    getLabelFromItem: {
      type: Function,
      default: (item) => {
        if (item.label) {
          return item.label;
        } else {
          return item;
        }
      },
    },
    selectionType: {
      type: String,
      default: '',
    },
    sortConfig: {
      type: Object,
      required: false,
      default: null,
    },
    optionsToExclude: {
      type: Array,
      required: false,
      default: () => [],
    },
    /**
     * Name of the labels where we want to apply the fallback => display user language if defined and otherwise display main language
     */
    fallBackConfig: {
      type: Object,
      required: false,
      default: () => {
        return {
          fallBackLabels: [],
          userLanguage: 'en_gb',
          mainLanguage: 'en_gb',
        };
      },
    },
    customIcon: {
      type: String,
      required: false,
      default: null,
    },
    customUserName: {
      type: String,
      required: false,
      default: null,
    },
    instantFocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconColor: {
      type: String,
      required: false,
      default: 'card',
    },
    transparentInput: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      items: [],
      isDropdownVisible: false,
      searchValue: '',
      displayCreation: false,
      formData: null,
      currentCustomAction: null,
      noMatchingResultText: this.t('hit-components.common.no-matching-result'),
      inputDomEl: null,
      hitSearchRef: null,
      inputBottomY: 0,
      inputTopY: 0,
      inputLeftX: 0,
      inputRightX: 0,
      tabPreselectedItemIndex: null,
    };
  },
  computed: {
    hasSelectedItemDesignationSlot() {
      return !!this.$slots['selectedItemDesignation'];
    },
    noItemsDropdown() {
      if (this.itemsToDisplay) {
        return Object.keys(this.itemsToDisplay).length;
      }
      return 0;
    },
    selectValue() {
      if (this.value) {
        if (this.multiple) {
          if (Array.isArray(this.value)) {
            const itemsToDisplayIds = this.itemsToDisplay.map(
              (item) => item.id
            );
            return this.value.filter((item) =>
              itemsToDisplayIds.includes(item)
            );
          } else {
            return [];
          }
        } else {
          return this.value;
        }
      } else {
        if (this.multiple) {
          return [];
        } else {
          return '';
        }
      }
    },
    displayCancelIcon() {
      return (
        !this.disabled &&
        this.selectValue !== '' &&
        !this.multiple &&
        this.clearable &&
        this.selectedItemLabel !== ''
      );
    },
    itemsToDisplay() {
      if (this.customFilter || !this.filterable) {
        return this.options;
      } else {
        let splitSearchValue = (this.searchValue || '')
          .split(' ')
          .map((elt) => elt.toLowerCase());
        if (Array.isArray(this.options)) {
          return this.options.filter((elt) => {
            return splitSearchValue.every((searchVal) => {
              return this.getItemLabel(elt).toLowerCase().includes(searchVal);
            });
          });
        } else {
          return Object.keys(this.options)
            .filter((key) => {
              return splitSearchValue.every((searchVal) => {
                return this.getItemLabel(this.options[key])
                  .toLowerCase()
                  .includes(searchVal);
              });
            })
            .reduce((obj, key) => {
              obj[key] = this.options[key];
              return obj;
            }, {});
        }
      }
    },
    itemsToDisplaySorted() {
      if (!this.sortConfig) {
        return this.itemsToDisplay.filter(
          (item) => !this.optionsToExclude.includes(item.id)
        );
      }
      let field = this.sortConfig.field;
      let order = this.sortConfig.order === 'asc';
      let result = this.itemsToDisplay;
      result.sort((item1, item2) => {
        if (!item1[field] && !item2[field]) {
          return 0;
        } else if (!item1[field]) {
          return -1;
        } else if (!item2[field]) {
          return 1;
        }
        return order
          ? item1[field].localeCompare(item2[field])
          : item2[field].localeCompare(item1[field]);
      });
      return result.filter((item) => !this.optionsToExclude.includes(item.id));
    },
    selectedItemLabel() {
      return this.multiple || this.loading
        ? ''
        : this.getItemLabel(this.getItemById(this.selectValue));
    },
    searchWidth() {
      let values = (Array.isArray(this.options)
        ? this.options
        : Object.values(this.options)
      ).map((elt) => this.getItemLabel(elt));

      let longestWord = values.reduce((longest, currentWord) => {
        if (currentWord.length > longest.length) {
          return currentWord;
        } else {
          return longest;
        }
      }, '');

      if (this.noItemsDropdown === 0) {
        longestWord = this.noMatchingResultText;
      }

      return TailwindUtils.getWidthFromWord(longestWord);
    },
    dropdownStyle() {
      let result = {};
      if (!this.inputDomEl) {
        return result;
      }
      // let dropdownSize = this.dropdownBottomY - this.dropdownTopY // dropdown size changes when scroll enabled so doesnt work
      //TODO get size of items dynamically
      let itemHeight = 40;
      let searchBarHeight = 50;
      let dropdownSize = this.options.length * itemHeight;
      if (this.filterable) {
        dropdownSize += searchBarHeight;
      }
      let limitTop = 0;
      //panel takes up whole space if display not high enough
      if (this.responsiveBreakpointHeightMd) {
        limitTop = 120; //120 is rough position of panel, which cuts off the dropdown,
      }
      let dropdownTooLargeForBottom =
        this.inputBottomY + dropdownSize > window.innerHeight;
      let dropdownTooLargeForTop = this.inputTopY - dropdownSize < limitTop;
      let bottomLimitToSwitchToScrollUpwards = 5 * itemHeight;
      if (this.filterable) {
        bottomLimitToSwitchToScrollUpwards += searchBarHeight;
      }
      let enableScrollbar = dropdownTooLargeForBottom && dropdownTooLargeForTop;
      let scrollUpwards =
        this.inputBottomY + bottomLimitToSwitchToScrollUpwards >
        window.innerHeight;
      let dropdownGoesUp =
        (dropdownTooLargeForBottom && !dropdownTooLargeForTop) ||
        (enableScrollbar && scrollUpwards);

      let spaceBetweenInputRightSideAndRightScreenEdge =
        window.innerWidth - this.inputRightX;
      let spaceBetweenInputLeftSideAndRightScreenEdge =
        window.innerWidth - this.inputLeftX;
      let spaceBetweenInputRightSideAndLeftScreenEdge = this.inputRightX;
      // width of dropdown should be around 200-250 but if one element is long it might be more, so it's better to account
      // for 300 I think. Any larger is a problem anyway since the design should work for a minimum screen width of 320
      let enoughSpaceLeftAligned =
        spaceBetweenInputLeftSideAndRightScreenEdge > 300;
      let enoughSpaceRightAligned =
        spaceBetweenInputRightSideAndLeftScreenEdge > 300;

      let alignDropDownRight =
        !enoughSpaceLeftAligned &&
        (enoughSpaceRightAligned ||
          spaceBetweenInputRightSideAndLeftScreenEdge >
            spaceBetweenInputLeftSideAndRightScreenEdge);

      if (alignDropDownRight) {
        result.right = spaceBetweenInputRightSideAndRightScreenEdge + 'px';
      } else {
        result.left = this.inputLeftX + 'px';
      }

      let height;
      if (dropdownGoesUp) {
        result.bottom = this.inputBottomY - this.inputTopY - 2 + 'px';
        height = this.options.length * itemHeight;
        if (this.filterable) {
          height += searchBarHeight;
        }
        result.height = height + 'px';
      } else {
        result.top = this.inputBottomY + 'px';
      }
      if (enableScrollbar) {
        result.overflow = 'auto';
        if (scrollUpwards) {
          height = this.inputTopY - limitTop;
        } else {
          height = window.innerHeight - this.inputBottomY - 16;
        }
        result.height = height + 'px';
      }
      if (dropdownGoesUp) {
        result.top = this.inputTopY - height - 1 + 'px';
      }
      return result;
    },
  },
  watch: {
    isDropdownVisible(newValue, oldValue) {
      let canvasDOM = document.getElementById('content-body-container'); //todo comment ligne 897 -> 914 (just add this.updateInputPosition();)
      let tableDOMs = document.getElementsByClassName('hit-table-content');
      if (!canvasDOM && tableDOMs.length === 0) {
        this.updateInputPosition();
        return;
      }
      if (newValue === true && oldValue === false) {
        canvasDOM.addEventListener('scroll', this.updateInputPosition);
        tableDOMs.forEach((tableDOM) => {
          tableDOM.addEventListener('scroll', this.updateInputPosition);
        });
        setTimeout(this.updateInputPosition, 1000);
      } else if (newValue === false && oldValue === true) {
        canvasDOM.removeEventListener('scroll', this.updateInputPosition);
        tableDOMs.forEach((tableDOM) => {
          tableDOM.removeEventListener('scroll', this.updateInputPosition);
        });
      }
      this.updateFilter();
    },
  },
  mounted() {
    if (this.instantFocus && this.inputDomEl) {
      this.inputDomEl.focus();
    }
  },
  methods: {
    hideCustomActionSlot() {
      this.showCustomActionSlot(undefined);
    },
    showCustomActionSlot(customAction) {
      if (this.currentCustomAction) {
        /**
         * When a custom action slot is hidden
         */
        this.$emit(`custom-slot-${this.currentCustomAction.id}:hide`);
      }
      if (customAction) {
        /**
         * When a custom slot is shown
         */
        this.$emit(`custom-slot-${customAction.id}:show`);
      }
      this.currentCustomAction = customAction;
    },
    getItemByIdFrom(id, items) {
      let result;
      if (Array.isArray(items)) {
        result = items.find((x) => {
          if (x === null) {
            return false;
          }
          return x[this.itemIdProperty ? this.itemIdProperty : 'id'] === id;
        });
        if (!result && this.selectionType === 'ENUM')
          result = items.find((x) => {
            if (x === null) {
              return false;
            }
            return Object.values(x).some((value) =>
              String(value).includes(String(id))
            );
          });
      } else {
        result = items[id];
      }
      return result;
    },
    getItemById(id) {
      let result = undefined;
      if (id) {
        result = this.getItemByIdFrom(id, this.options);
        if (!result && this.selectedOptions) {
          result = this.getItemByIdFrom(id, this.selectedOptions);
        }
        if (!result && !this.loading && !this.optionsLoading) {
          // console.error(
          //   `Error in getItemById `,
          //   id,
          //   this.options,
          //   this.selectedOptions
          // );
        }
      }

      return result;
    },
    onChange(value) {
      if (this.validationState) {
        this.validationState.$touch();
      }
      this.fireInputChange(value);
    },
    onClickOutside(event) {
      // FIXME: stop propagation does not work and so the clickOutside is triggered
      // FIXME: => cleaner if click.stop is working and we don't need to check the target of the click
      try {
        if (
          event.target.id !== 'search' &&
          event.target.parentElement.id !== 'clearHitSearch'
        ) {
          this.closeDropdown();
        }
      } catch {
        this.closeDropdown();
      }
    },
    clearInput() {
      this.$emit('clear');
      this.onChange(null);
    },
    updateInputPosition() {
      if (this.inputDomEl) {
        let rect = this.inputDomEl.getBoundingClientRect();
        if (
          rect.bottom !== 0 &&
          rect.top !== 0 &&
          rect.left !== 0 &&
          rect.right !== 0
        ) {
          this.inputBottomY = rect.bottom;
          this.inputTopY = rect.top;
          this.inputLeftX = rect.left;
          this.inputRightX = rect.right;
        }
      }
    },
    closeDropdown() {
      this.isDropdownVisible = false;
      this.hideCustomActionSlot();
    },
    updateFilter() {
      if (!this.isDropdownVisible) {
        this.filter(null);
      } else {
        this.updateInputPosition();
        this.$emit('show-options');
        nextTick(() => {
          if (this.hitSearchRef && this.hitSearchRef.inputDomEl) {
            this.hitSearchRef.inputDomEl.focus();
          }
        });
      }
    },
    toggleDropdownVisibility() {
      if (!this.disabled) {
        this.isDropdownVisible = !this.isDropdownVisible;
      }
    },
    filter(value) {
      this.resetPreselectedItem();
      if (value != this.searchValue) {
        this.searchValue = value;
        /**
         * When the options are filtered
         */
        this.$emit('filter', value);
      }
    },
    isItemSelected(value) {
      return this.multiple
        ? Array.isArray(this.value) && this.value.includes(value)
        : this.value === value;
    },
    isItemPreselectedViaTab(value) {
      return this.tabPreselectedItemIndex !== null
        ? value === this.itemsToDisplaySorted[this.tabPreselectedItemIndex].id
        : false;
    },
    onSelect(itemId, item = null) {
      if (!this.disabled) {
        if (
          (this.multiple && !this.selectValue.includes(itemId)) ||
          !this.multiple
        ) {
          if (this.selectionType === 'ENUM' && item) {
            const optionsArray = Object.values(this.options);
            const foundIndex = optionsArray.findIndex(
              (option) => option.enum_label === item.enum_label
            );
            itemId = foundIndex;
          }
          this.selectItem(itemId);
        } else {
          this.unselectItem(itemId);
        }
      }
    },
    selectItem(itemId) {
      let result = itemId;
      if (this.multiple) {
        result = this.selectValue;
        result.push(itemId);
      } else {
        this.isDropdownVisible = false;
      }
      this.onChange(result);
      this.$emit('add', itemId);
    },
    unselectItem(itemId) {
      let result = this.selectValue;
      result = result.filter((elt) => elt !== itemId);
      this.onChange(result);
      this.$emit('remove', itemId);
    },
    getItemLabel(item, dropdown) {
      if (item) {
        if (dropdown && this.dropdownLabelFields.length > 0) {
          return this.dropdownLabelFields
            .map((elt) => item[elt])
            .join(this.labelSeparator);
        } else if (this.labelFields.length > 0) {
          const localisedLabels = [];
          const handledFallbacks = [];
          this.labelFields.forEach((l) => {
            if (
              l[l.length - 3] === '_' &&
              l[l.length - 6] === '_' &&
              this.fallBackConfig.fallBackLabels.includes(l.split('_')[0])
            ) {
              if (!handledFallbacks.includes(l.split('_')[0])) {
                const userLanguageKey =
                  l.split('_')[0] + '_' + this.fallBackConfig.userLanguage;
                const mainLanguageKey =
                  l.split('_')[0] + '_' + this.fallBackConfig.mainLanguage;
                localisedLabels.push(
                  item[userLanguageKey] ? userLanguageKey : mainLanguageKey
                );
                handledFallbacks.push(l.split('_')[0]);
              }
            } else {
              localisedLabels.push(l);
            }
          });
          return localisedLabels
            .map((elt) => item[elt])
            .join(this.labelSeparator);
        } else {
          if (item.label) {
            return this.getLabelFromItem(item.label);
          } else {
            return this.getLabelFromItem(item);
          }
        }
      } else {
        return '';
      }
    },
    getItemBackground(selectedValue) {
      const {colour} =
        this.options.find((item) => item.id === selectedValue) || {};
      return colour ? `#${colour}` : 'var(--color-tag)';
    },
    getItemFontColour(selectedValue) {
      const bgColor = this.getItemBackground(selectedValue);
      return ColourUtils.getFontColorOnBg(bgColor);
    },
    incrementPreselectedItem() {
      if (this.tabPreselectedItemIndex === null) {
        this.tabPreselectedItemIndex = 0;
      } else {
        this.tabPreselectedItemIndex =
          (this.tabPreselectedItemIndex + 1) % this.itemsToDisplaySorted.length;
      }
      this.scrollToPreselectedItem();
    },
    resetPreselectedItem() {
      this.tabPreselectedItemIndex = null;
    },
    scrollToPreselectedItem() {
      const itemToScrollTo =
        this.tabPreselectedItemIndex === null
          ? this.itemsToDisplaySorted[0]
          : this.itemsToDisplaySorted[this.tabPreselectedItemIndex];
      this.$nextTick(() => {
        let element = document.getElementById(
          'select-item-' + itemToScrollTo.id
        );
        if (element) {
          const bounding = element.getBoundingClientRect();
          if (bounding.top < 0 || bounding.bottom > window.innerHeight) {
            this.$nextTick(() => {
              element.scrollIntoView();
            });
          }
        }
      });
    },
    handleEnterClick() {
      if (!this.isDropdownVisible) {
        this.isDropdownVisible = true;
        return;
      }
      if (this.tabPreselectedItemIndex !== null) {
        this.onSelect(
          this.itemsToDisplaySorted[this.tabPreselectedItemIndex].id,
          this.itemsToDisplaySorted[this.tabPreselectedItemIndex]
        );
      }
      this.tabPreselectedItemIndex = null;
      this.isDropdownVisible = false;
      this.inputDomEl.focus();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.hit-select {
  grid-template-columns: 1fr max-content;

  &.clear {
    grid-template-columns: 1fr max-content max-content;
  }
}

.creation-menu {
  grid-template-areas:
    'custom-action-header'
    'custom-action-body';
  grid-template-rows: max-content 1fr;
}

.custom-action-header {
  //grid-template: custom-action-header;
  grid-template-columns: max-content 1fr;
}

.custom-action-body {
  //grid-template: custom-action-body;
  grid-template-rows: 1fr max-content;
}
</style>

<style lang="scss">
.hit-select-item {
  a:focus,
  a:hover {
    color: white;
  }
}

.border-padding {
  padding-right: 7px;
  padding-left: 7px;
}
</style>
